import ColorButtonGroup from "@/common/Product/ColorButtonGroup";
import { Group } from "@mantine/core";
import React from "react";

export default function ProductCardVariantionColors() {
  return (
    <Group position="center">
      <div>
        <ColorButtonGroup />
      </div>
    </Group>
  );
}
