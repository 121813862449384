import { Skeleton, Text } from "@mantine/core";
import numeral from "numeral";
import React from "react";
import { useProductController } from "../ProductController";
import { useRouter } from "next/router";

export default function Price() {
  const { locale } = useRouter();
  const lienhe = (locale==="vi"?"Liên hệ":"Contact");

  const { isLoading, isDiscounted, effectivePrice, originalPrice } =
    useProductController();

  return (
    <Skeleton visible={isLoading}>
      <>
        <Text
          size="sm"
          weight="normal"
          sx={(theme) => ({
            color: theme.colors.gray[7],
            display: "inline-block",
          })}
        >
          {effectivePrice?`${numeral(effectivePrice).format()} VND`:lienhe}
        </Text>
        {/* giá giảm */}
        {isDiscounted && (
          <Text
            size="sm"
            sx={(theme) => ({
              color: theme.colors.green[9],
              // marginLeft: 12,
              textDecoration: "line-through",
              display: "inline-block",
            })}
          >
            {originalPrice?`${numeral(originalPrice).format()} VND`:lienhe}
          </Text>
        )}
      </>
    </Skeleton>
  );
}
