import { useLocalContext } from "@/setup/locale-provider";
import { appAlert } from "@/setup/mantine-provider/notifications";
import { Button } from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";
import AddToCartButton from "../buttons/AddToCartButton";
import CallToAction from "../buttons/CallToActions";
import { useProductController } from "../ProductController";

export default function AddToCartButtonGroup({ slXuat }) {
  const { push, locale } = useRouter();

  const {
    isStockAvailable,
    isProcessingCart,
    addToCart,
    OutOfStockReminderModalRef,
    effectivePrice, originalPrice 
  } = useProductController();
  if(!effectivePrice && !originalPrice) return null;
  const { getLabel } = useLocalContext();
  return (
    <>
      {isStockAvailable !== false && (
        <Button.Group>
          <AddToCartButton
            linkToCart
            compact={false}
            label={locale === "vi" ? "MUA NGAY" : "BUY"}
            isStockAvailable={isStockAvailable}
            loading={isProcessingCart}
            onClickHandler={() => {
              if (!isProcessingCart && isStockAvailable) {
                addToCart(slXuat);
                push("/cart");
              }
            }}
            customStyle={{ marginRight: 2 }}
          />
          <AddToCartButton
            compact={false}
            loading={isProcessingCart}
            label={locale === "vi" ? "THÊM VÀO GIỎ" : "ADD TO CART"}
            isStockAvailable={isStockAvailable}
            onClickHandler={() => {
              if (!isProcessingCart && isStockAvailable) {
                addToCart(slXuat);
                appAlert({
                  type: "success",
                  message:
                    locale === "vi"
                      ? "Cập nhật giỏ hàng thành công"
                      : "Cart update successful",
                });
              }
            }}
            customStyle={{ marginLeft: 2 }}
          />
        </Button.Group>
      )}

      {isStockAvailable === false && (
        <CallToAction
          compact={false}
          customStyle={{
            width: "100%",
            borderRadius: 0,
          }}
          onClick={() => {
            OutOfStockReminderModalRef.current.openModal();
          }}
        >
          {locale === "vi" ? "Thông báo khi có hàng" : "Notify when in stock"}
        </CallToAction>
      )}
    </>
  );
}
