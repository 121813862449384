import { Button } from "@mantine/core";
import React from "react";

export default function SizeButton({
  size,
  selectedSize,
  isOutOfStock,
  ...props
}) {
  // console.log(1);

  return (
    <Button
      variant="outline"
      compact="true"
      sx={(theme) => {
        return {
          fontSize: 11,
          paddingLeft: 0,
          paddingRight: 0,
          width: "28px !important",
          height: "28px !important",
          aspectRatio: "1",
          borderRadius: "50%",
          color:
            selectedSize === size ? theme.colors.gray[9] : theme.colors.gray[5],
          borderColor:
            selectedSize === size ? theme.colors.gray[9] : theme.colors.gray[5],

          ...(isOutOfStock === true && {
            backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cline x1='0' y1='100' x2='100' y2='0' stroke-width='2' fill='black' stroke='%230B2437'/%3e%3c/svg%3e")`,
            // backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cline x1='0' y1='100' x2='100' y2='0' stroke-width='1' fill='black' stroke='%230B2437'/%3e%3c/svg%3e")`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center center`,
            backgroundSize: `100% 100%, auto`,
            backgroundColor: "transparent",
          }),
        };
      }}
      {...props}
    >
      {size}
    </Button>
  );
}
