import { isEmpty } from "@/utils/lodash";
import { useQuery } from "@tanstack/react-query";
import unique from "just-unique";
import { asyncSearchList } from "../fetch";
import { dmchietkhauKeys } from "../queryKeys";

export const fetchPromotedList = async ({ signal }) => {
  const data = await asyncSearchList({
    access_token: process.env.public_token,
    collection_name: "dmchietkhau",
    options: {
      limit: 200,
      fields: ["hieu_luc_tu", "hieu_luc_den", "ma_vt"],
    },
    signal,
  });
  const ma_vt_list = (data || [])
    .sort((a, b) => b.tien_ck - a.tien_ck)
    .map((el) => el.ma_vt);

  return unique(ma_vt_list);
};

export const fetchDmchietkhau = async (condition) => {
  if (!condition || isEmpty(condition)) return;

  return await asyncSearchList({
    access_token: process.env.public_token,
    collection_name: "dmchietkhau",
    options: {
      condition,
    },
  });
};

export const useFetchDmchietkhauByMaVt = ({ ma_vt, enabled = true }) => {
  return useQuery(
    dmchietkhauKeys.list(ma_vt),
    () => fetchDmchietkhau({ ma_vt }),
    {
      enabled: !!ma_vt && enabled,
      staleTime: 1000 * 60 * 60,
    }
  );
};
export const useFetchDmchietkhauByMaTt = ({ ma_tt, enabled = true }) => {
  return useQuery(
    dmchietkhauKeys.detail(ma_tt),
    () => fetchDmchietkhau({ ma_tt }),
    {
      enabled: !!ma_tt && enabled,
      staleTime: 1000 * 60 * 60,
    }
  );
};

export const useFetchPromotedList = () => {
  return useQuery(
    dmchietkhauKeys.all(),
    ({ signal }) => fetchPromotedList({ signal }),
    {
      staleTime: 1000 * 60 * 60,
    }
  );
};
