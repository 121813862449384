import React from "react";
import CallToAction from "./CallToActions";

export default function AddToCartButton({
  compact = true,
  onClickHandler = () => {},
  label = "THÊM VÀO GIỎ",
  linkToCart = false,
  isStockAvailable = undefined,
  loading = false,
  customStyle = {},
}) {
  return (
    <CallToAction
      compact={compact}
      onClick={() => {
        onClickHandler();
      }}
      component={linkToCart ? "a" : "button"}
      disabled={isStockAvailable !== true}
      customStyle={{
        width: "50%",
        ...customStyle,
      }}
      loading={loading}
      loaderProps={{
        color: "dark",
      }}
    >
      {label}
    </CallToAction>
  );
}
