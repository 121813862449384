import { ImageSliderArrows } from "@/common/ProductCard/components/CustomSliderArrows";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import Slider from "@ant-design/react-slick";
import styled from "@emotion/styled";
import { Box, CloseButton, Modal } from "@mantine/core";
import Image from "next/image";
import PlaceholderImage from "public/images/placeholder-image.jpeg";
import React from "react";

const StyledModal = styled(Modal)`
  .mantine-Modal-modal {
    padding: 0;
    overflow: hidden;
  }
`;

export default function ZoomedImagesModal({ isOpen, images, handleClose }) {
  const zoomedMainSlider = React.useRef();

  const zoomedMainSettings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: 0,
    nextArrow: <ImageSliderArrows />,
    prevArrow: <ImageSliderArrows />,
  };

  return (
    <StyledModal
      opened={isOpen}
      onClose={() => handleClose()}
      fullScreen
      withCloseButton={false}
    >
      <CloseButton
        sx={(theme) => ({
          position: "absolute",
          right: 10,
          top: 10,
          borderRadius: "50%",
          zIndex: 999,
          borderColor: theme.colors.gray[4],
        })}
        color="dark"
        onClick={() => handleClose()}
        variant="subtle"
        title="Close popover"
        size="xl"
        iconSize={20}
        style={{ outline: "none" }}
      />
      <Slider {...zoomedMainSettings} ref={zoomedMainSlider}>
        {(images || []).map((image, i) => (
          <Box
            key={i}
            sx={{ width: "100%", height: "100vh", position: "relative" }}
          >
            <Image
              key={i}
              src={
                image.hinh_anh
                  ? appendImageUrlFromAPI({ src: image.hinh_anh })
                  : PlaceholderImage
              }
              alt="product image"
              layout="fill"
              objectFit="contain"
            />
          </Box>
        ))}
      </Slider>
    </StyledModal>
  );
}
