import { useSetState } from "@mantine/hooks";
import React from "react";

export default function useProductVariations(dmtt) {
  const [
    { colors, sizes, selectedColor, selectedSize, selectedDmtt },
    setState,
  ] = useSetState({
    colors: [],
    sizes: [],
    selectedColor: undefined,
    selectedSize: undefined,
    selectedDmtt: undefined,
  });

  const handleSelectColor = (_selectedColor) =>
    setState({ selectedColor: _selectedColor?.toLowerCase() });
  const handleSelectSize = (_selectedSize) =>
    setState({ selectedSize: _selectedSize });

  React.useEffect(() => {
    if (!dmtt || dmtt.length === 0) return;
    const variants = {};
    dmtt.map((el) => {
      if (!el.details || el.details.length === 0) return;

      el.details?.reduce((acc, curr) => {
        if (!acc[curr.ma_bien_the]) acc[curr.ma_bien_the] = [];
        if (
          acc[curr.ma_bien_the] &&
          !acc[curr.ma_bien_the].includes(curr.gia_tri_bien_the)
        ) {
          acc[curr.ma_bien_the].push(curr.gia_tri_bien_the);
        }

        return acc;
      }, variants);
    });

    const uniqueColors = [
      ...new Set(variants?.COLOR?.map((el) => el?.toLowerCase())),
    ];
    const uniqueSizes = [...new Set(variants?.SIZE?.map(Number))];
    const sortedUniqueColors = uniqueColors.sort(() => 1);
    const sortedUniqueSizes = uniqueSizes.sort((a, b) => a - b);

    setState({
      colors: sortedUniqueColors,
      sizes: sortedUniqueSizes,
    });
  }, [dmtt]);

  React.useEffect(() => {
    if (
      !colors ||
      colors.length === 0 ||
      !sizes ||
      sizes.length === 0 ||
      !selectedColor ||
      !selectedSize
    )
      return;

    const matchingDmtt = dmtt?.find((el) =>
      el.details.every(
        (detail) =>
          detail.gia_tri_bien_the?.toString().toLowerCase() ===
            selectedColor?.toString().toLowerCase() ||
          detail.gia_tri_bien_the?.toString().toLowerCase() ===
            selectedSize?.toString().toLowerCase()
      )
    );

    setState({ selectedDmtt: matchingDmtt });
  }, [sizes, colors, dmtt, selectedColor, selectedSize]);

  return {
    colors,
    sizes,
    selectedColor,
    selectedSize,
    selectedDmtt,
    handleSelectColor,
    handleSelectSize,
  };
}
