import { Box, HoverCard, MediaQuery } from "@mantine/core";
import React from "react";

export default function ProductCardHoveringSection({ children }) {
  return (
    <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
      <HoverCard.Dropdown>
        <Box sx={{ position: "relative" }}>{children}</Box>
      </HoverCard.Dropdown>
    </MediaQuery>
  );
}
