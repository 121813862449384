import Slider from "@ant-design/react-slick";
import { Box } from "@mantine/core";
import React from "react";

import { useProductController } from "@/common/ProductController";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { useProductCardContext } from "../ProductCardView";

export default function ProductCardVariationSizes() {
  // const { renderSizeButtons } = useProductController();
  const { productCardWidth } = useProductCardContext();
  const SizeButtonWidth = 26 + 8;

  function getButtonCount() {
    if (productCardWidth >= 265) return 6;
    if (productCardWidth >= 220) return 5;
    if (productCardWidth >= 200) return 4;
    return 3;
  }

  function ArrowLeft({ className, style, onClick }) {
    return (
      <FiChevronLeft
        className={className}
        style={{
          display: "block",
          color: "#000",
          zIndex: 12,
          ...style,
        }}
        onClick={onClick}
      />
    );
  }
  function ArrowRight({ className, style, onClick }) {
    return (
      <FiChevronRight
        className={className}
        style={{
          display: "block",
          color: "#000",
          zIndex: 12,
          ...style,
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: getButtonCount(),
    slidesToScroll: 2,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  return (
    <Box
      mt={4}
      mb={10}
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        width: getButtonCount() * SizeButtonWidth,
      }}
    >
      {/* <Slider {...settings}>{renderSizeButtons()}</Slider> */}
    </Box>
  );
}
