import { useProductController } from "@/common/ProductController";
import { Box, Text } from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";

export default function ProductCardStockTag() {
  const { isStockAvailable } = useProductController();
  const { locale } = useRouter();

  return (
    !isStockAvailable && (
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "5%",
          right: "5%",
          backgroundColor: theme.colors.gray[5],
          aspectRatio: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.white,
          borderRadius: "2px",
          width: 60,
          height: 25,
          zIndex: 4,
        })}
      >
        <Text size="xs">{locale === "vi" ? "Hết hàng" : "Sold out"}</Text>
      </Box>
    )
  );
}
