import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
export function ImageSliderArrows(props) {
  const { className, style, onClick } = props;

  if (className.includes("slick-prev")) {
    return (
      <FiChevronLeft
        className={className}
        style={{
          display: "block",
          color: "#fff",
          zIndex: 6,
          backgroundColor: "#000",
          opacity: 0.2,
          width: 24,
          height: 24,
          borderRadius: "50%",
          left: 4,
          ...style,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <FiChevronRight
      className={className}
      style={{
        display: "block",
        color: "#fff",
        zIndex: 6,
        backgroundColor: "#000",
        opacity: 0.2,
        width: 24,
        height: 24,
        borderRadius: "50%",
        position: "absolute",
        right: 4,
        ...style,
      }}
      onClick={onClick}
    />
  );
}

export function ButtonSliderArrows(props) {
  const { className, style, onClick } = props;

  if (className.includes("slick-prev")) {
    return (
      <FiChevronLeft
        className={className}
        style={{
          display: "block",
          color: "#000",
          zIndex: 6,
          ...style,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <FiChevronRight
      className={className}
      style={{
        display: "block",
        color: "#000",
        zIndex: 6,
        ...style,
      }}
      onClick={onClick}
    />
  );
}
