import OutOfStockBadge from "@/common/Badges/OutOfStockBadge";
import { useProductController } from "@/common/ProductController";
import { Group } from "@mantine/core";
import React from "react";

export default function ProductOutOfStockBadge() {
  const { isStockAvailable } = useProductController();

  return (
    isStockAvailable === false && (
      <Group position="center" mb={10}>
        <OutOfStockBadge />
      </Group>
    )
  );
}
