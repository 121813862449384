import { postSubscribe } from "@/apis/queryFunctions/subscribe";
import { appAlert } from "@/setup/mantine-provider/notifications";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { useMutation } from "@tanstack/react-query";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

const OutOfStockReminderModal = React.forwardRef(
  ({ product = {}, selectedSize, selectedColor, picture_tt }, ref) => {
    const isMobile = useMediaQuery("(max-width: 576px)");
    const [opened, setOpened] = React.useState(false);
    const { locale } = useRouter();
    const { mutate, isLoading } = useMutation(postSubscribe, {
      onSuccess: (rs) => {
        if (rs._id) {
          setOpened(false);
          appAlert({
            type: "success",
            message:
              locale === "vi"
                ? "Đăng kí nhận thông tin thành công!"
                : "Sign up for information successfully!",
          });
        } else {
          appAlert({
            message:
              locale === "vi"
                ? "Đăng ký nhận thông tin không thành công"
                : "Sign up for information failed!",
          });
        }
      },
    });

    const form = useForm({
      initialValues: {
        email: "",
        termsOfService: false,
      },

      validate: {
        email: (value) =>
          /^\S+@\S+$/.test(value)
            ? null
            : locale === "vi"
            ? "Email không hợp lệ"
            : "Invalid email",
        termsOfService: (val) =>
          val
            ? null
            : locale === "vi"
            ? "Đánh dấu vào ô để tiếp tục."
            : "Check the box to continue",
      },
    });

    React.useImperativeHandle(ref, () => ({
      openModal() {
        form.reset();
        setOpened(true);
      },
    }));

    const handleSubmit = (email) => {
      mutate({ email });
    };

    const renderInput = () => (
      <>
        <TextInput
          my={20}
          placeholder={locale === "vi" ? "Địa chỉ email" : "Email"}
          {...form.getInputProps("email")}
        />
        <Box
          sx={() => ({
            marginTop: "auto",
          })}
        >
          <Box mb={16}>
            <Checkbox
              label={
                locale === "vi"
                  ? "Tôi đồng ý rằng Viet Thien có thể liên hệ với tôi để cung cấp thông tin liên quan đến sản phẩm và dịch vụ"
                  : "I agree that Viet Thien may contact me to provide information regarding products and services"
              }
              {...form.getInputProps("termsOfService", {
                type: "checkbox",
              })}
              color="dark"
            />
            {form.errors.termsOfService && (
              <Text ml={30} color="red">
                {form.errors.termsOfService}
              </Text>
            )}
          </Box>
          <Button
            color="dark"
            variant="outline"
            fullWidth
            type="submit"
            loading={isLoading}
          >
            {locale === "vi" ? "Gửi" : "Send"}
          </Button>
        </Box>
      </>
    );

    return (
      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Title order={5}>
            {locale === "vi"
              ? "Thông báo cho tôi khi có hàng"
              : "Notify me when stock is available"}
          </Title>
        }
        size="xl"
      >
        <form onSubmit={form.onSubmit(({ email }) => handleSubmit(email))}>
          <Grid>
            <Grid.Col span={5}>
              <Image
                alt={product?.ten_vt}
                src={appendImageUrlFromAPI({
                  src: picture_tt || product?.picture,
                  size: "m",
                })}
                height={600}
                width={600}
                objectFit="contain"
                layout="responsive"
                quality={100}
              />
            </Grid.Col>
            <Grid.Col span={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Title order={6}>
                  {locale === "vi" ? product?.ten_vt : product?.ten_vt_eng}
                </Title>
                {(selectedColor || selectedSize) && (
                  <Text
                    weight={500}
                  >{`${selectedColor}, ${selectedSize}`}</Text>
                )}
                {!isMobile && renderInput()}
              </Box>
            </Grid.Col>
          </Grid>
          <Box>{isMobile && renderInput()}</Box>
        </form>
      </Modal>
    );
  }
);

OutOfStockReminderModal.displayName = "OutOfStockReminderModal";

export default OutOfStockReminderModal;
