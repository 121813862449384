import { useSetState } from "@mantine/hooks";
import { useEffect } from "react";

function calculatePercentage(from, to) {
  if (!from) return 0;
  return ((to - from) / from) * 100;
}

function calculateDiscountPriceByPercentage(price, discountPercentage) {
  return price - price * (discountPercentage / 100);
}

function usePricing({
  product: _product = { discount: [] },
  // selectedDmtt,
  discounts,
  productVariant,
}) {
  const product = productVariant || _product;

  const [{ effectivePrice, originalPrice, message }, setState] = useSetState({
    effectivePrice: product?.gia_ban_le || product?.gia_ban_le0,
    originalPrice: product?.gia_ban_le || product?.gia_ban_le0,
    message: "",
  });

  const handleUpdateDiscountRate = ({ discount }) => {
    if (!discount?.tien_ck && !discount?.ty_le_ck) {
      setState({ effectivePrice: product.gia_ban_le });
      setState({ originalPrice: product.gia_ban_le });
      return;
    }
    if (discount.tien_ck) {
      setState({ effectivePrice: product.gia_ban_le - discount.tien_ck });
      setState({ originalPrice: product.gia_ban_le });
      return;
    }
    if (discount.ty_le_ck) {
      setState({
        effectivePrice: calculateDiscountPriceByPercentage(
          product.gia_ban_le,
          discount.ty_le_ck
        ),
      });
      setState({ originalPrice: product.gia_ban_le });
    }
  };

  // initially setting up discount rate
  useEffect(() => {
    if (!product || !product.ma_vt) return;
    let discount = {};
    if (discounts && discounts.length > 0) {
      discount = discounts.find((el) => el.ma_vt === product.ma_vt);
    }

    handleUpdateDiscountRate({ discount });
  }, [product, discounts]);

  // useEffect(() => {
  //   if (!selectedDmtt) return;
  //   let discount = {};
  //   if (discounts && discounts.length > 0) {
  //     discount = discounts.find((el) => el.ma_tt1 === selectedDmtt.ma_tt);
  //   }

  //   // setState({
  //   //   effectivePrice: selectedDmtt.gia_ban_le - (discount?.tien_ck || 0),
  //   //   originalPrice: selectedDmtt.gia_ban_le,
  //   // });

  //   handleUpdateDiscountRate({ discount, product: selectedDmtt });
  // }, [selectedDmtt, discounts]);

  return {
    isDiscounted: effectivePrice < originalPrice,
    effectivePrice: +effectivePrice > 0 ? +effectivePrice : 0,
    originalPrice: +originalPrice,
    discountPercent: +Math.round(
      calculatePercentage(originalPrice, effectivePrice),
      0
    ),
    message,
  };
}

export default usePricing;
