import useWindowSize from "@/hooks/useWindowSize";
import { Box, HoverCard } from "@mantine/core";
import { useElementSize, useMediaQuery } from "@mantine/hooks";
import * as React from "react";
import OutOfStockReminderModal from "../Modal/OutOfStockReminder";
import { useProductController } from "../ProductController";
import ProductCardButton from "./components/ProductCardButton";
import ProductCardDiscount from "./components/ProductCardDiscountTag";
import ProductCardHoveringSection from "./components/ProductCardHoveringSection";
import ProductCardImage from "./components/ProductCardImages";
import ProductCardPrice from "./components/ProductCardPrice";
import ProductCardStockTag from "./components/ProductCardStockTag";
import ProductCardTitle from "./components/ProductCardTitle";
import ProductCardVariantSelection from "./components/ProductCardVariantSelection";
import ProductCardVariantionColors from "./components/ProductCardVariationColors";
import ProductCardVariationSizes from "./components/ProductCardVariationSizes";
import ProductCartBox from "./components/ProductCartBox";
import ProductOutOfStockBadge from "./components/ProductOutOfStockBadge";

const ProductCardContext = React.createContext();

export const useProductCardContext = () => {
  const context = React.useContext(ProductCardContext);
  return context;
};

function ProductCardView({ children }) {
  const {
    product,
    selectedColor,
    selectedSize,
    OutOfStockReminderModalRef,
    stocks,
    dmtt,
    setEnabledFetchingQueries,
    picture_tt,
  } = useProductController();

  const [hovered, setHovered] = React.useState(false);
  const {
    ref: productCardSizeRef,
    width: productCardWidth,
    height: productCardHeight,
  } = useElementSize();

  const { xs } = useWindowSize();
  const isMobile = useMediaQuery(`(max-width: ${xs}px)`, false, {
    getInitialValueInEffect: false,
  });

  React.useEffect(() => {
    setEnabledFetchingQueries({
      shouldEnableFetchingDmtt: !dmtt,
      shouldEnableFetchingStock: !stocks,
    });
  }, [])

  React.useEffect(() => {
    if (!hovered || isMobile) return;

    setEnabledFetchingQueries({
      shouldEnableFetchingDmtt: !dmtt,
      shouldEnableFetchingStock: !stocks,
    });
  }, [hovered, isMobile, dmtt?.length, stocks?.length]);

  const value = React.useMemo(
    () => ({ hovered, productCardWidth, productCardHeight }),
    [hovered, productCardWidth, productCardHeight]
  );

  return (
    <ProductCardContext.Provider value={value}>
      <Box
        sx={{
          position: "relative",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
          transition: "0.3s",
          "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
          },
        }}
        ref={productCardSizeRef}
      >
        <HoverCard
          width="target"
          position="bottom"
          withArrow={false}
          shadow="md"
          offset={-40}
          openDelay={dmtt ? 200 : 400}
          closeDelay={200}
          withinPortal
          onOpen={() => setHovered(true)}
          onClose={() => setHovered(false)}
          middlewares={{
            flip: false,
          }}
        >
          <HoverCard.Target>
            <Box>{children}</Box>
          </HoverCard.Target>
        </HoverCard>
        <OutOfStockReminderModal
          product={product}
          selectedColor={selectedColor}
          selectedSize={selectedSize}
          ref={OutOfStockReminderModalRef}
          picture_tt={picture_tt}
        />
      </Box>
    </ProductCardContext.Provider>
  );
}

ProductCardView.Title = ProductCardTitle;
ProductCardView.Button = ProductCardButton;
ProductCardView.DiscountTag = ProductCardDiscount;
ProductCardView.Images = ProductCardImage;
ProductCardView.Price = ProductCardPrice;
ProductCardView.Colors = ProductCardVariantionColors;
ProductCardView.Sizes = ProductCardVariationSizes;
ProductCardView.ShowOnHover = ProductCardHoveringSection;
ProductCardView.VariantSelection = ProductCardVariantSelection;
ProductCardView.OutOfStockBadge = ProductOutOfStockBadge;
ProductCardView.Box = ProductCartBox;
ProductCardView.StockTag = ProductCardStockTag;

export default ProductCardView;
