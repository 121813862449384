import React from "react";
import ColorButton from "../buttons/ColorButton";
import { useProductController } from "../ProductController";

export default function ColorButtonGroup() {
  const { colors, handleSelectColor, selectedColor } = useProductController();

  const sortedColors =
    colors &&
    colors?.length > 0 &&
    colors.sort((a, b) => {
      if (a?.toLowerCase() < b?.toLowerCase()) return 1;
      if (a?.toLowerCase() > b?.toLowerCase()) return -1;
      return 0;
    });

  return (sortedColors || []).map((color, i) => (
    <ColorButton
      key={i}
      mr={8}
      onClick={() => {
        handleSelectColor(color?.toLowerCase());
      }}
      color={color}
      isSelected={selectedColor?.toLowerCase() === color?.toLowerCase()}
    />
  ));
}
