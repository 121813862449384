import { isEmpty } from "@/utils/lodash";
import { useSetState } from "@mantine/hooks";
import React from "react";

export default function useProductImages(product, selectedColor) {
  const [
    { allImages, selectedImageColorVariant, selectedImageIndex, picture_tt },
    setImages,
  ] = useSetState({
    allImages: null,
    selectedImageColorVariant: null,
    selectedImageIndex: null,
    picture_tt: null,
  });

  React.useEffect(() => {
    if (!product || isEmpty(product)) return;

    const productImages = [];

    if (product?.picture) {
      productImages.push({
        hinh_anh: product.picture,
        ten_tt1: null,
      });
    }
    if (product?.picture2) {
      productImages.push({
        hinh_anh: product.picture2,
        ten_tt1: null,
      });
    }
    if (product?.picture3) {
      productImages.push({
        hinh_anh: product.picture3,
        ten_tt1: null,
      });
    }

    let variationImages = [];

    if (product?.images?.length > 0) {
      variationImages = product.images?.reduce((acc, curr) => {
        if (curr.hinh_anh)
          acc.push({
            hinh_anh: curr.hinh_anh,
            ten_tt1: curr.ma_tt3?.toLowerCase(),
          });
        if (curr.hinh_anh_2)
          acc.push({
            hinh_anh: curr.hinh_anh_2,
            ten_tt1: curr.ma_tt3?.toLowerCase(),
          });
        if (curr.hinh_anh_3)
          acc.push({
            hinh_anh: curr.hinh_anh_3,
            ten_tt1: curr.ma_tt3?.toLowerCase(),
          });

        return acc;
      }, []);
    }

    let sortedVariationImages = [];

    if (variationImages.length > 0) {
      sortedVariationImages = variationImages.sort((a, b) => {
        if (a?.ten_tt1 < b?.ten_tt1) return 1;
        if (a?.ten_tt1 > b?.ten_tt1) return -1;
        return 0;
      });
    }

    const images = [...productImages, ...sortedVariationImages];

    setImages({ allImages: images });
  }, [product]);

  React.useEffect(() => {
    if (!allImages?.length) return;
    if (!selectedColor) {
      setImages({ selectedImageIndex: 0 });
      return;
    }

    const index = allImages.findIndex(
      (image) => image.ten_tt1 === selectedColor.toLowerCase()
    );

    setImages({
      selectedImageIndex: index || 0,
      picture_tt: allImages?.[index]?.hinh_anh,
    });
  }, [selectedColor, allImages]);

  return {
    allImages,
    selectedImageColorVariant,
    // handleSelectImage,
    selectedImageIndex,
    picture_tt,
  };
}
