import { useFetchDmchietkhauByMaVt } from "@/apis/queryFunctions/dmchietkhauFunctions";
import { useFetchDmtt } from "@/apis/queryFunctions/dmttFunctions";
import {
  useFetchProduct,
  useFetchStockQuantity,
} from "@/apis/queryFunctions/productFunctions";
import usePrevious from "@/hooks/usePrevious";
import usePricing from "@/hooks/usePricing";
import useProductAvailability from "@/hooks/useProductAvailability";
import useProductImages from "@/hooks/useProductImages";
import useProductVariations from "@/hooks/useProductVariations";
import { useCartContext } from "@/setup/cartProvider";
import { appAlert } from "@/setup/mantine-provider/notifications";
import checkIsOutOfStock from "@/utils/checkIsOutOfStock";
import { useSetState } from "@mantine/hooks";
import * as React from "react";
import SizeButton from "../buttons/SizeButton";

const ProductContext = React.createContext();

export const useProductController = () => {
  const context = React.useContext(ProductContext);
  return context;
};

export default function ProductController({
  children,
  product = {},
  isLoading,
}) {
  const { addToCart: _addToCart, isProcessingCart } = useCartContext();
  const [
    { shouldEnableFetchingDmtt, shouldEnableFetchingStock },
    setEnabledFetchingQueries,
  ] = useSetState({
    shouldEnableFetchingDmtt: false,
    shouldEnableFetchingStock: false,
  });

  const { data: dmtt, isLoading: isLoadingDmtt } = useFetchDmtt({
    ma_vt: product?.ma_vt,
    enabled: shouldEnableFetchingDmtt,
  });

  let ma_vt_tt = [];
  if (dmtt && dmtt?.length > 0) {
    ma_vt_tt = dmtt.map((el) => el.ma_tt);
  }
  if (dmtt && dmtt?.length === 0) {
    ma_vt_tt = [product?.ma_vt];
  }

  const { data: stocks } = useFetchStockQuantity({
    ma_vt_tt,
    ma_vt: product?.ma_vt,
    enabled: product?.kg_kiem_tra_ton_kho ? false : shouldEnableFetchingStock,
  });
  // console.log("------");
  // console.log("ma_vt_tt", ma_vt_tt);
  // console.log("ma_vt", product?.ma_vt);
  // console.log("stocks", stocks);
  // console.log("------");

  const OutOfStockReminderModalRef = React.useRef();

  const {
    // colors,
    // sizes,
    // selectedColor,
    // selectedSize,
    // handleSelectColor,
    // handleSelectSize,
    selectedDmtt,
  } = useProductVariations(dmtt);

  // const prevColor = usePrevious(selectedColor);
  const { data: productVariant } = useFetchProduct({
    ma_vt: selectedDmtt?.ma_tt,
  });

  const { data: discounts } = useFetchDmchietkhauByMaVt({
    ma_vt: selectedDmtt?.ma_tt || product?.ma_vt,
  });

  const isStockAvailable = useProductAvailability({
    // dmtt,
    stocks,
    product,
    // selectedSize,
    // selectedColor,
    // colors,
  });

  // React.useEffect(() => {
  //   if (prevColor && prevColor !== selectedColor) {
  //     handleSelectSize(null);
  //   }
  // }, [selectedColor, prevColor]);

  const { allImages, selectedImageIndex, picture_tt } = useProductImages(
    product
    // selectedColor
  );

  const { isDiscounted, effectivePrice, originalPrice } = usePricing({
    product,
    discounts,
    productVariant,
  });

  async function addToCart(sl = 1) {
    const postData = {
      sl_xuat: sl,

      ma_dvt: product?.ma_dvt,
      thuoc_tinh: {
        shippingInfo: {
          chieu_cao: product?.chieu_cao,
          chieu_rong: product?.chieu_rong,
          chieu_sau: product?.chieu_sau,
          trong_luong: product?.trong_luong,
        },
        voucher: {},
        ...(selectedDmtt || {}),
        // gia_tri_bien_the_combined: [selectedColor, selectedSize],
      },
      // id_cs: payload.discount.find((i) => i.ma_cs)?.id_cs,
      id_app_vt: product?.id_app,
      id_app: product?.id_app,
      tg_tk: product?.tg_tk,
      gia_ban_le: effectivePrice,
      ma_vt: selectedDmtt?.ma_tt || product?.ma_vt,
      ma_vt_tt: selectedDmtt?.ma_tt || product?.ma_vt,
      picture_tt: picture_tt || product?.picture,
      ...(productVariant || {}),
    };
    _addToCart(postData);
  }

  // const renderSizeButtons = () => {
  //   if (!sizes || sizes?.length === 0) return null;

  //   return sizes.map((size, i) => (
  //     <SizeButton
  //       key={size}
  //       onClick={() => {
  //         if (!selectedColor) {
  //           appAlert({
  //             message: "Vui lòng chọn màu sắc trước",
  //           });
  //           return;
  //         }
  //         handleSelectSize(size);
  //       }}
  //       size={size}
  //       selectedSize={selectedSize}
  //       // ml={i === 0 ? 0 : 4}
  //       mr={i === size.length - 1 ? 0 : 8}
  //       isOutOfStock={checkIsOutOfStock({
  //         selectedSize: size,
  //         selectedColor,
  //         dmtt,
  //         stocks,
  //       })}
  //     >
  //       {size}
  //     </SizeButton>
  //   ));
  // };

  return (
    <ProductContext.Provider
      value={{
        isLoading,
        product,
        dmtt,
        isLoadingDmtt,
        addToCart,
        // colors,
        // sizes,
        // selectedColor,
        // selectedSize,
        // handleSelectColor,
        // handleSelectSize,
        selectedDmtt,
        stocks,
        isProcessingCart,
        discounts,
        isStockAvailable,
        allImages,
        selectedImageIndex,
        // prevColor,
        isDiscounted,
        effectivePrice,
        originalPrice,
        OutOfStockReminderModalRef,
        setEnabledFetchingQueries,
        // renderSizeButtons,
        picture_tt,
        productVariant,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}
