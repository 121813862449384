import Price from "@/common/Product/Price";
import { Box } from "@mantine/core";
import React from "react";

export default function ProductCardPrice() {
  return (
    <Box sx={{ textAlign: "left", marginTop: 6 }}>
      <Price />
    </Box>
  );
}
