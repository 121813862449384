import AddToCartButtonGroup from "@/common/Product/AddToCartButtonGroup";
import CoverImage from "@/common/Product/CoverImage";
import { useProductController } from "@/common/ProductController";
import useProductImages from "@/hooks/useProductImages";
import slugify from "@/utils/slugifyString";
import { Box, Skeleton } from "@mantine/core";
import useEmblaCarousel from "embla-carousel-react";
import Link from "next/link";
import React from "react";
import { useProductCardContext } from "../ProductCardView";

export default function ProductCardImage({ children }) {
  const { hovered } = useProductCardContext();
  const { product, selectedColor, isLoading, isLoadingDmtt } =
    useProductController();

  const { selectedImageIndex } = useProductImages(product, selectedColor);
  const [sliderRef, sliderAPI] = useEmblaCarousel({ loop: false });

  React.useEffect(() => {
    if (sliderAPI) {
      sliderAPI?.scrollTo?.(selectedImageIndex, true);
    }
  }, [selectedImageIndex, sliderAPI]);

  return (
    <Box sx={{ position: "relative" }}>
      <Skeleton
        visible={isLoading && isLoadingDmtt}
        sx={{ overflow: "visible", aspectRatio: "1" }}
      >
        {children}
        <Link
          scroll={false}
          href={`/products/${slugify(product?.ten_vt)}-${product?.ma_vt}`.toLowerCase()}
        >
          <Box
            sx={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: "pointer",
              position: "absolute",
              zIndex: 2,
            }}
          />
        </Link>
        <Box
          sx={{
            // top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: "pointer",
            position: "absolute",
            zIndex: 2,
            // transition: "transform 0.5s",
          }}
        >
          {hovered && <AddToCartButtonGroup />}
        </Box>
        <CoverImage
          sliderRef={sliderRef}
          sliderAPI={sliderAPI}
          size="s"
          showArrows={hovered}
        />
      </Skeleton>
    </Box>
  );
}
