import { useProductController } from "@/common/ProductController";
import { Box } from "@mantine/core";
import React from "react";

export default function ProductCardVariantSelection({ children }) {
  const { dmtt } = useProductController();
  const hasVariants = dmtt && dmtt.length > 0;

  return (
    hasVariants && (
      <Box
        sx={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {children}
      </Box>
    )
  );
}
