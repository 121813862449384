import compareStrings from "./compareStrings";

export const colorsMaps = {
  "nâu bò": "#ae693d",
  vàng: "#e9d55e",
  "xanh ngọc": "#adbec3",
  trắng: "#ffffff",
  kem: "#f3dfab",
  đen: "#000000",
  xanh: "#1c72bd",
  xám: "#aaaaaa",
  nâu: "#ae693d",
  "xanh mint": "#04d9b2",
  rêu: "#193919",
  "xanh navy": "#2e5b7c",
  tím: "#6a4aad",
  hồng: "#f79ab9",
  "nhũ bạc": "#9fa2b2",
  bạc: "#bec2cb",
  đỏ: "#dd3234",
  nude: "#fadbd4",
  "xanh dương": "#7bc8ed",
  "xanh da trời": "#7bc8ed",
  bò: "#ae693d",
  "kem mờ": "#f9f0d8",
};

export function getColorCode(colorName) {
  if (!colorName || typeof colorName !== "string") return null;
  let colorCode = "green";

  for (const key in colorsMaps) {
    if (compareStrings(key, colorName)) {
      colorCode = colorsMaps[key];
    }
  }
  return colorCode;
}
