import { getColorCode } from "@/utils/colorMapping";
import { Button } from "@mantine/core";
import React from "react";

export default function ColorButton({ color, isSelected, ...props }) {
  return (
    <Button
      compact
      sx={(theme) => ({
        background: `${getColorCode(color)} content-box`,
        aspectRatio: "1",
        borderRadius: "50%",
        width: 28,
        height: 28,
        borderColor: isSelected ? theme.colors.gray[9] : theme.colors.gray[3],
        borderWidth: 2,
        padding: 2,
        "&:hover": {
          background: `${getColorCode(color)} content-box`,
        },
      })}
      {...props}
    />
  );
}
