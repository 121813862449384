import { flattenObj } from "@/utils/lodash";

const sort = {
  date_created: -1,
};

// export const INITIAL_LOADING_NEWS_OPTIONS = {
//   limit: process.env.NEWS_LOADING_LIMIT,
//   condition: {
//     ngon_ngu: "vi",
//   },
// };

const dmttKeys = {
  // all: [{ scope: "dmtt", limit: 100, sort }],
  // count: (options) => [
  //   {
  //     scope: "dmtt",
  //     type: "count",
  //     ...flattenObj(options || {}),
  //   },
  // ],
  list: () => [
    {
      scope: "dmtt",
      type: "list",
    },
  ],

  detail: (ma_vt) => [{ scope: "dmtt", type: "detail", ma_vt }],
};

export default dmttKeys;
