import { useProductController } from "@/common/ProductController";
import slugify from "@/utils/slugifyString";
import { Box } from "@mantine/core";
import Link from "next/link";
import React from "react";

export default function ProductCartBox({ children }) {
  const { product } = useProductController();
  return (
    <Link
      scroll={false}
      href={`/products/${slugify(product?.ten_vt)}-${product?.ma_vt}`.toLowerCase()}
    >
      <Box
        p="xs"
        sx={{
          cursor: "pointer",
        }}
      >
        {children}
      </Box>
    </Link>
  );
}
