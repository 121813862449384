// import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import React from "react";

export default function CallToAction({ children, customStyle, ...props }) {
  return (
    <Button
      sx={(theme) => ({
        fontSize: 12,
        paddingLeft: 0,
        paddingRight: 0,
        color: "white",
        // borderColor: theme.colors.gray[5],
        backgroundColor: "#000",
        transition: "0.5s",
        "&[data-disabled]": {
          // color: theme.colors.gray[5],
          // borderColor: theme.colors.gray[5],
          backgroundColor: "#00723B",
        },
        "&:hover": {
          backgroundColor: "#00723B",
        },
        ...customStyle,
      })}
      {...props}
    >
      {children}
    </Button>
  );
}
