import { useQuery, useQueryClient } from "@tanstack/react-query";

import { isEmpty } from "@/utils/lodash";

import { asyncGet, asyncGetItem, asyncGetList } from "../fetch";
import { productKeys } from "../queryKeys";

const DEFAULT_STALE_TIME = 60 * 60; // 1 hour

export const initialOptions = {
  limit: process.env.COLLECTION_LOADING_NUMBER,
  page: 1,
  notfields: [
    "mieu_ta",
    "gia_xuat",
    "tk_vt",
    "gia_ban_buon0",
    "gia_ban_buon",
    "gia_mua",
    "thue_suat_nk0",
    "thue_suat_nk",
    "labels",
    "cong_doan_sx",
    "ma_lvt",
    "ma_nvt",
    "tk_dt",
    "tk_gv",
    "mieu_ta_ngan",
    "name_user_created",
    "name_user_updated",
    "ten_tk_vt",
    "ten_tk_dt",
    "ten_tk_gv",
    "ten_tk_tl",
    "ten_tk_dl",
    "ten_thue",
  ],
  condition: {
    id_app: process.env.id_app,
    status: true,
    by_user: true,
    show_properties: true,
    online: true,
    shared: true,
    gia_ban_le: {
      $gte: 0,
    },
  },
  sort: {
    date_updated: -1,
  },
};
export const getBestSellerOptions = (ma_danh_muc_cty) => ({
  limit: 6,
  sort: {
    date_created: -1,
  },
  condition: {
    ma_danh_muc_cty: {
      $regex: ma_danh_muc_cty,
    },
  },
});

export const fetchProduct = async ({ ma_vt, signal }) =>
  asyncGetItem({
    collection_name: "dmvt",
    signal,
    options: {
      condition: {
        id_app: process.env.id_app,
        status: true,
        by_user: true,
        online: true,
        ma_vt,
        shared: true,
        ma_danh_muc_cty: {
          $in: [undefined, null],
        },
        // images: {
        //   $nin: [undefined, null],
        // },
      },
    },
  });

export const useFetchProduct = ({ ma_vt }) =>
  useQuery(
    productKeys.detail(ma_vt),
    ({ signal }) => fetchProduct({ ma_vt, signal }),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled: !!ma_vt,
    }
  );

/* -------------------------------------------------------------------------- */

export const fetchProducts = async ({ userToken, options = {}, signal, categories }) => {
  const token = userToken;
  // console.log('token', token
  const conditionCategories = categories
    ? {
        ma_danh_muc_cty: {
          $in: categories,
        },
      }
    : null;
  return await asyncGetList({
    access_token:token,
    collection_name: "dmvt",
    signal,
    options: {
      ...options,
      condition: {
        ...options.condition,
        ...conditionCategories,
        // images: {
        //   $nin: [undefined, null],
        // },
        shared: true,
        status: true,
        gia_theo_kh: true,

        // ma_danh_muc_cty: {
        //   $in: [undefined, null],
        // },
      },
    },
  });
};

export const useFetchProducts = (options) => {
  const queryClient = useQueryClient();

  return useQuery(
    productKeys.list(options),
    ({ signal }) => fetchProducts({ options, signal }),
    {
      enabled: !!options,
      staleTime: DEFAULT_STALE_TIME,
      onSuccess: (products) => {
        products.map((product) => {
          queryClient.setQueriesData(
            productKeys.detail(product?.ma_vt),
            product
          );
        });
      },
    }
  );
};
export const useFetchProductCollection = (options, categories) => {
  const token = typeof window !== 'undefined' ? 
    JSON.parse(window.localStorage.getItem("token")) || process.env.public_token :
    process.env.public_token;  
  const queryClient = useQueryClient();

  return useQuery(
    productKeys.list({
      page: options?.page || 1,
      sort: options?.sort,
      ...options?.condition,
      ...categories,
    }),
    ({ signal }) =>
      fetchProducts({
        userToken: token,
        options: {
          ...options,
          limit: process.env.COLLECTION_LOADING_NUMBER,
        },
        signal,
        categories,
      }),
    {
      enabled: !isEmpty(options),
      staleTime: DEFAULT_STALE_TIME,
      onSuccess: (products) => {
        products.map((product) => {
          queryClient.setQueriesData(
            productKeys.detail(product?.ma_vt),
            product
          );
        });
      },
    }
  );
};

export const useFetchHomePageProducts = ({ ma_danh_muc_cty }) => {
  const queryClient = useQueryClient();
  const options = getBestSellerOptions(ma_danh_muc_cty);
  return useQuery(
    productKeys.homeList(ma_danh_muc_cty),
    ({ signal }) =>
      fetchProducts({
        signal,
        options,
      }),
    {
      enabled: Boolean(ma_danh_muc_cty),
      // staleTime: DEFAULT_STALE_TIME,
      onSuccess: (products) => {
        products.map((product) => {
          queryClient.setQueriesData(
            productKeys.detail(product?.ma_vt),
            product
          );
        });
      },
    }
  );
};

/* -------------------------------------------------------------------------- */

export const fetchProductsCount = async ({ options = {}, signal }) => {
  const data = await asyncGetList({
    collection_name: "dmvt",
    signal,
    options: {
      count: true,
      condition: {
        ...(options?.condition || {}),
        status: true,
        shared: true,
        // ma_danh_muc_cty: {
        //   $nin: [undefined, null],
        // },
        // ma_danh_muc_cty: {
        //   ...(options?.condition?.ma_danh_muc_cty || {}),
        //   $nin: [undefined, null],
        // },
        // images: {
        //   $nin: [undefined, null],
        // },
      },
    },
  });
  return data?.rows_number || 0;
};

export const useFetchProductCount = ({ options }) =>
  useQuery(
    productKeys.count(options),
    ({ signal }) => fetchProductsCount({ options, signal }),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled: !isEmpty(options),
    }
  );

/* -------------------------------------------------------------------------- */
export const fetchStocks = async ({ ma_vt_tt, signal, enabled }) => {
  try {
    if (!enabled) return [];
    const url = `${process.env.server_url}/api/${
      process.env.id_app
    }/ckvt?access_token=${
      process.env.public_token
    }&groupby=["ma_vt"]&ma_vt=${JSON.stringify(
      ma_vt_tt
    )}&rpt=1&fields=ton,ma_vt&ma_kho=KCTY`;
    const stocks = await asyncGet(url, signal);

    // console.log("stocks", stocks);

    return stocks;
  } catch (error) {
    console.error(error);
  }
};

export const useFetchStockQuantity = ({ ma_vt_tt, enabled, ma_vt }) =>
  useQuery(
    [{ scope: "stockQuantity", type: "detail", ma_vt }],
    ({ signal }) => fetchStocks({ ma_vt_tt, signal, enabled }),
    {
      // enabled: !!ma_vt_tt && ma_vt_tt?.length > 0 && enabled,
      // staleTime: 0,
      staleTime: 500 * 60 * 60,
    }
  );
