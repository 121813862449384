import { useProductController } from "@/common/ProductController";
import usePricing from "@/hooks/usePricing";
import { Box, Text } from "@mantine/core";
import React from "react";

export default function ProductCardDiscount() {
  const { product } = useProductController();

  const { isDiscounted, discountPercent } = usePricing({
    product,
  });

  return (
    isDiscounted && (
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "5%",
          left: "5%",
          backgroundColor: theme.colors.main[5],
          aspectRatio: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.white,
          borderRadius: "50%",
          width: 45,
          height: 45,
          zIndex: 4,
        })}
      >
        <Text size="lg" weight="500">
          {`${discountPercent}%`}
        </Text>
      </Box>
    )
  );
}
