import { useQuery } from "@tanstack/react-query";
import { asyncGetList } from "../fetch";
import dmttKeys from "../queryKeys/dmttKeys";
import allDmtt from "./initialData/dmtt.json";

export function fetchDmtt({ ma_vt }) {
  if (!ma_vt) return null;

  return asyncGetList({
    access_token: process.env.public_token,
    collection_name: "dmtt",
    options: {
      notfields: [
        "ten_vt",
        "ten_tt",
        "ten_trang_thai",
        "ten_dvt",
        "name_user_created",
        "name_user_updated",
        "ma_dvt",
        "ma_ct",
        "loai_tt",
        "id_app",
        "color",
        "trang_thai",
      ],
      limit: 200,
      condition: {
        ma_vt,
      },
    },
  });
}

export function fetchAllDmtt() {
  return asyncGetList({
    access_token: process.env.public_token,
    collection_name: "dmtt",
    options: {
      limit: 3000,
      fields: ["ma_vt", "gia_tri_bien_the_1", "gia_tri_bien_the_2"],
    },
  });
}

export const useFetchDmtt = ({ ma_vt, enabled = true }) =>
  useQuery(dmttKeys.detail(ma_vt), () => fetchDmtt({ ma_vt }), {
    enabled: !!ma_vt && enabled,
    staleTime: 1000 * 60 * 60,
  });

export const useFetchDmttList = () =>
  useQuery(dmttKeys.list(), () => fetchAllDmtt(), {
    staleTime: 1000 * 60 * 60 * 4,
    initialData: allDmtt,
  });
