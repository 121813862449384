import checkIsOutOfStock from "@/utils/checkIsOutOfStock";
import React from "react";

export default function useProductAvailability({ stocks, product }) {
  const [isStockAvailable, setState] = React.useState(undefined);

  React.useEffect(() => {
    if (!stocks || (stocks.length === 0 && !product?.kg_kiem_tra_ton_kho)) {
      setState(false);

      return;
    }
    setState(true);
  }, [stocks?.length]);

  return isStockAvailable;
}
