import { useProductController } from "@/common/ProductController";
import { useLocalContext } from "@/setup/locale-provider";
import { Skeleton, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useRouter } from "next/router";
import React from "react";
import { useProductCardContext } from "../ProductCardView";

export default function ProductCardTitle() {
  const { product, isLoading } = useProductController();
  const { hovered } = useProductCardContext();
  const isMobile = useMediaQuery("(max-width: 576px)");

  function checkVisibility() {
    if (isMobile) return "visible";
    // if (hovered) return "hidden";
    return "visible";
  }
  const { getLabel } = useLocalContext();
  const { locale } = useRouter();
  return (
    <Skeleton visible={isLoading}>
      <Text
        size="sm"
        align="left"
        sx={{ visibility: checkVisibility(), minHeight: 40 }}
        weight="bold"
        lineClamp={2}
      >
        {getLabel(locale === "vi" ? product?.ten_vt : product?.ten_vt_eng)}{" "}
        {/* {product?.ma_vt?.toUpperCase()} */}
      </Text>
    </Skeleton>
  );
}
