import ZoomedImagesModal from "@/pages/products/components/ZoomedImagesModal";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import Image from "next/image";
import PlaceholderImage from "public/images/placeholder-image.jpeg";
import React from "react";
import { useProductController } from "../ProductController";

export default function CoverImage({
  enableZoom = false,
  sliderRef,
  sliderAPI,
  showArrows = false,
  size = "m",
}) {
  const { allImages, product } = useProductController();
  const [isZoomed, setIsZoomed] = React.useState(false);
  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);

  const onSelect = React.useCallback(() => {
    if (!sliderAPI) return;
    setPrevBtnEnabled(sliderAPI.canScrollPrev());
    setNextBtnEnabled(sliderAPI.canScrollNext());
  }, [sliderAPI]);

  // TEMP FIX: carousel not loading initally
  React.useEffect(() => {
    if (!sliderAPI) return;

    sliderAPI.reInit();
  }, [sliderAPI]);

  React.useEffect(() => {
    if (!sliderAPI || !onSelect) return;
    onSelect();
    sliderAPI.on("select", onSelect);
  }, [sliderAPI, onSelect]);

  const scrollPrev = React.useCallback(() => {
    if (sliderAPI) sliderAPI.scrollPrev();
  }, [sliderAPI]);

  const scrollNext = React.useCallback(() => {
    if (sliderAPI) sliderAPI.scrollNext();
  }, [sliderAPI]);

  return (
    <>
      <ZoomedImagesModal
        isOpen={enableZoom && isZoomed}
        handleClose={() => setIsZoomed(false)}
        images={allImages}
        product={product}
      />
      <div className="productCoverImageSlider">
        <div className="productCoverImageSlider__viewport" ref={sliderRef}>
          <div className="productCoverImageSlider__container">
            {/* <div className="productCoverImageSlider__slide">
              <Image
                src={
                  product?.picture
                    ? appendImageUrlFromAPI({ src: product?.picture, size })
                    : PlaceholderImage
                }
                height={600}
                width={600}
                alt={product?.ten_vt}
                objectFit="contain"
                layout="responsive"
                quality={85}
                onClick={() => {
                  setIsZoomed(true);
                }}
                priority
              />
            </div> */}
            {allImages &&
              allImages?.length > 0 &&
              allImages?.map((image, index) => (
                <div
                  className="productCoverImageSlider__slide"
                  key={image?.hinh_anh}
                >
                  <Image
                    src={
                      image.hinh_anh
                        ? appendImageUrlFromAPI({ src: image.hinh_anh, size })
                        : PlaceholderImage
                    }
                    height={600}
                    width={600}
                    alt={product?.ten_vt}
                    objectFit="contain"
                    objectPosition="center"
                    layout="responsive"
                    quality={85}
                    onClick={() => {
                      setIsZoomed(true);
                    }}
                    priority={index === 0}
                  />
                </div>
              ))}
          </div>
        </div>
        {showArrows && (
          <button
            className="productCoverImageSlider__button productCoverImageSlider__prev"
            onClick={scrollPrev}
            type="button"
            disabled={!prevBtnEnabled}
          >
            <FiChevronLeft />
          </button>
        )}
        {showArrows && (
          <button
            className="productCoverImageSlider__button productCoverImageSlider__next"
            onClick={scrollNext}
            type="button"
            disabled={!nextBtnEnabled}
          >
            <FiChevronRight />
          </button>
        )}
      </div>
    </>
  );
}
