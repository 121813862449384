import * as React from "react";
import ProductController from "../ProductController";
import ProductCardView from "./ProductCardView";

function ProductCard({ product, isLoading }) {
  return (
    <ProductController product={product} isLoading={isLoading}>
      <ProductCardView>
        <ProductCardView.Images>
          <ProductCardView.DiscountTag />
          <ProductCardView.StockTag />
        </ProductCardView.Images>
        <ProductCardView.Box>
          <ProductCardView.Title />
          <ProductCardView.Price />
        </ProductCardView.Box>
        {/* <ProductCardView.ShowOnHover>
          <ProductCardView.VariantSelection>
            <ProductCardView.Colors />
            <ProductCardView.Sizes />
          </ProductCardView.VariantSelection>
          <ProductCardView.OutOfStockBadge />
          <ProductCardView.Button />
        </ProductCardView.ShowOnHover> */}
      </ProductCardView>
    </ProductController>
  );
}

export default ProductCard;
